import { ConsentDto, ConsentStatus, ConsentType } from 'graphql/generates';

export const PersonConsentType = {
  Gdpr: ConsentType.Eewe,
  Finleap: ConsentType.FinleapConnect,
};

export const GdprConsentStatus = {
  Granted: ConsentStatus.EinwilligungErteilt,
  Sent: ConsentStatus.Versendet,
  Created: ConsentStatus.Erstellt,
  Uploaded: ConsentStatus.Hochgeladen,
  Missing: ConsentStatus.NichtVorhanden,
};

export const FinleapConsentStatus = {
  Granted: ConsentStatus.ConsentGranted,
  Sent: ConsentStatus.EmailSent,
  Created: ConsentStatus.AccountConnected,
  Missing: ConsentStatus.NichtVorhanden,
};

export type FinleapConsent = Omit<ConsentDto, 'advertisementEmail' | 'advertisementPhone'>;
