import { DeepMap, FieldError, FieldValues } from 'react-hook-form';

import { Status } from 'utils/status';
import {
  TEmailFormFieldError,
  TFaxFormFieldError,
  TTelephoneFormFieldError,
} from '../type/contact';
import { TAddressFieldError } from '../type/address';

export const isFieldRequired = (
  fieldName: string,
  formErrors:
    | TAddressFieldError
    | TEmailFormFieldError
    | TTelephoneFormFieldError
    | TFaxFormFieldError
    | DeepMap<FieldValues, FieldError>,
): boolean => {
  const errorType = formErrors?.[fieldName as keyof typeof formErrors]?.type;
  const isRequiredError = errorType && errorType === Status.required;
  return isRequiredError;
};
