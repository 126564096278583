import accounting from 'accounting';

import { isGermanLocale } from 'locale/i18n';

export const displayCurrency = (
  amount: string,
  noSign = false,
  currency = '€',
  valueIfMissing = '--',
  precision = 2,
) => {
  let symbol: Record<string, unknown> = { symbol: currency };
  let format = '%v %s';
  const parseNumber = parseFloat(amount);
  const localeFormat = isGermanLocale()
    ? {
        decimal: ',',
        thousand: '.',
      }
    : {
        decimal: '.',
        thousand: ',',
      };

  if (Number.isNaN(parseNumber) && !parseNumber) {
    return valueIfMissing;
  }

  if (noSign) {
    symbol = {};
    format = '%v';
  }
  return accounting.formatMoney(amount, {
    ...symbol,
    format,
    ...localeFormat,
    precision,
  });
};
