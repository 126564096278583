import { useMutation } from '@tanstack/react-query';

import { personApi } from 'service/api/personApi';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { queryKeyStr } from 'utils/util';
import { useNotification } from 'utils/notification/NotificationContext';

export const useDeleteCommunicationChannel = (personId: string | undefined) => {
  const { showError } = useNotification();
  const personService = personApi();

  return useMutation<{ status: number }, ErrorRequestEntity, string>({
    mutationKey: [`${queryKeyStr}${personId}`],
    mutationFn: async (communicationChannelId) =>
      personService.deleteCommunicationChannel(communicationChannelId, `${personId}`),
    onError: (error) => {
      if (error?.response?.status !== 400) {
        showError({ status: error?.response?.status });
      }
    },
  });
};
