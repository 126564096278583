import { ConsentStatus } from 'graphql/generates';
import i18next from 'i18next';
import {
  FinleapConsent,
  FinleapConsentStatus,
  GdprConsentStatus,
  PersonConsentType,
} from '../type/consent';

export const getConsentStatusText = (status?: ConsentStatus) => {
  const consentStatus = status || ConsentStatus.NichtVorhanden;
  return {
    [ConsentStatus.AccountConnected]: i18next.t('consent.status.accountConnected'),
    [ConsentStatus.ConsentGranted]: i18next.t('consent.status.granted'),
    [ConsentStatus.EinwilligungErteilt]: i18next.t('consent.status.granted'),
    [ConsentStatus.EmailSent]: i18next.t('consent.status.sent'),
    [ConsentStatus.Versendet]: i18next.t('consent.status.sent'),
    [ConsentStatus.Erstellt]: i18next.t('consent.status.created'),
    [ConsentStatus.Hochgeladen]: i18next.t('consent.status.uploaded'),
    [ConsentStatus.NichtVorhanden]: i18next.t('consent.status.missing'),
  }[consentStatus];
};

export const isGdprConsentMissing = (consent?: FinleapConsent) =>
  consent &&
  consent.type === PersonConsentType.Gdpr &&
  consent.status === GdprConsentStatus.Missing;

export const isGdprConsentGranted = (consent?: FinleapConsent) =>
  consent &&
  consent.type === PersonConsentType.Gdpr &&
  consent.status === GdprConsentStatus.Granted;

export const isFinleapConsentGranted = (consent?: FinleapConsent) =>
  consent &&
  consent.type === PersonConsentType.Finleap &&
  consent.status === FinleapConsentStatus.Granted;
