import i18next from 'i18next';

type DateValue = string | undefined | null;

export const formatDate = (value: Date) => value.toISOString().split('T')[0];

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const changeDateFormat = (date: string) =>
  new Date(date).toLocaleDateString(navigator.language, dateFormatOptions);

const currentDate = new Date();

export const MINDATE = formatDate(new Date('1920-01-01'));
export const MAXDATE = formatDate(
  new Date(currentDate.setFullYear(currentDate.getFullYear() + 100)),
);
export const CURRENTDATE = formatDate(new Date());

export const oldDataError = i18next.t('general.oldDateError', {
  date: changeDateFormat(MINDATE),
});
export const futureDateError = i18next.t('general.longFutureDateError', {
  date: changeDateFormat(CURRENTDATE),
});
export const futureBirthDateError = i18next.t('general.futureBirthDateError');

export const validateMinDate = (value: DateValue): boolean => {
  if (!value) return true;
  const selectedDate = formatDate(new Date(value));
  const isDateBeforeMinDate = selectedDate < MINDATE;
  if (isDateBeforeMinDate) return false;
  return true;
};

export const validateMaxDate = (value: DateValue): boolean => {
  if (!value) return true;
  const selectedDate = formatDate(new Date(value));
  const isDateAfterMaxDate = selectedDate > MAXDATE;
  if (isDateAfterMaxDate) return false;
  return true;
};

export const validateGreaterThenCurrentDate = (value: DateValue): boolean => {
  if (!value) return true;
  const selectedDate = formatDate(new Date(value));
  const isDateAfterMaxDate = selectedDate > CURRENTDATE;
  if (isDateAfterMaxDate) return false;
  return true;
};
